import { useHydrationRef } from '#imports'
import { useConfig } from '@/stores/config'

export function relativeKey (key: string | string[]) {
  const { currentRegion } = useConfig()
  const currentLocale = useHydrationRef('current-locale', '')
  const formattedKey = Array.isArray(key) ? key.join('_') : key

  return [
    currentLocale.value,
    currentRegion.value?.id,
    clearKey(formattedKey)
  ].filter(Boolean).join(':')
}

export function absoluteKey (key: string | string[]) {
  const formattedKey = Array.isArray(key) ? key.join('_') : key

  return clearKey(formattedKey)
}

export function clearKey (key: string) {
  return key.replace(/\//g, '_')
    .replace(/\\/g, '_')
    .replace(/:/g, '_')
    .replace(/\s/g, '_')
    .replace(/\?/g, '_')
}
