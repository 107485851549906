import { useContextData } from '@/modules/nuxt-utils/runtime/context-data'
import type * as Sentry from '@sentry/node'

export function useSentry () {
  const sentry = useNuxtApp().$sentry
  const sentryScope = useContextData<Sentry.Scope | null>('sentry-scope', null).value

  function captureException (exception: Error) {
    sentry?.captureException(exception, () => sentryScope)
  }

  function addBreadcrumb (breadcrumb: Sentry.Breadcrumb) {
    sentry?.addBreadcrumb(breadcrumb, () => sentryScope)
  }

  function setTag (tag: string, value: string) {
    sentryScope?.setTag(tag, value)
  }

  return {
    addBreadcrumb,
    captureException,
    setTag
  }
}
