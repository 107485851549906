import { toRef } from 'vue'
import { useNuxtApp } from '#app'
import { useAPI, useSharedPromise, useHydrationStore } from '#imports'

import type { BannerCollection } from '@/modules/nuxt-api/models/Banner'

type State = {
  catalogBanners: Record<string, BannerCollection[]>
}

export function useBanners () {
  const nuxtApp = useNuxtApp()
  const api = useAPI()
  const state = useHydrationStore<State>('banners-store', {
    catalogBanners: {}
  })

  async function getBannersForHome (): Promise<BannerCollection[]> {
    const data = await nuxtApp.runWithContext(() => useSharedPromise('home-banners', async () => {
      const { getBannersForHome } = api.banner()
      return await getBannersForHome()
    }))

    return data
  }

  async function getBannersForCatalog (section: string) {
    if (!state.value.catalogBanners[section]?.length) {
      const { getBannersForCatalog } = api.banner()
      state.value.catalogBanners[section] = await getBannersForCatalog(section)
    }

    return state.value.catalogBanners[section]
  }

  return {
    getBannersForHome,
    getBannersForCatalog,

    catalogBanners: toRef(state.value, 'catalogBanners')
  }
}
