import { useNuxtApp } from '#app'
import { toRef } from 'vue'

import type { Ref } from 'vue'

export function useHydrationRef<T = any> (
  key: string,
  defaultValue?: T
) {
  const nuxt = useNuxtApp()
  const data: Ref<T> = toRef(nuxt.payload.data, key)

  if (!data.value && defaultValue) {
    data.value = defaultValue
  }

  return data
}
