import { useContextData } from '#imports'
import { relativeKey } from '@/utils/nitro/keygen'

export async function useSharedPromise<T> (key: string | string[], callback: () => Promise<T>) {
  const promises = useContextData<any>('shared-promise', {})
  const formattedKey = relativeKey(key)

  if (!promises.value[formattedKey]) {
    promises.value[formattedKey] = callback()
      .then((data) => {
        return data
      })
      .finally(() => {
        promises.value[formattedKey] = undefined
      })
  }

  return promises.value[formattedKey] as T
}
