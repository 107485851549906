import type { H3Event } from 'h3'
import { customRef } from 'vue'

export function useContextData<T> (
  key: string,
  defaultValue?: T,
  event?: H3Event,
  options?: { fromNuxtContext: boolean }
) {
  function getPayload () {
    const payload: any = useNuxtApp().payload

    if (!payload.context) {
      payload.context = {}
    }

    return payload
  }

  const data = customRef<T>(() => ({
    get () {
      if (import.meta.server) {
        let resEvent = event

        if (!resEvent && options?.fromNuxtContext) {
          resEvent = useNuxtApp().ssrContext?.event
        }

        if (resEvent) {
          if (!resEvent.context) {
            resEvent.context = {}
          }

          return resEvent.context[key]
        }
      }

      if (import.meta.client) {
        return getPayload().context?.[key]
      }
    },
    set (value) {
      if (import.meta.server) {
        try {
          if (!event) {
            event = useNuxtApp().ssrContext?.event
          }
        } catch (e) {}

        if (event) {
          const resEvent = event

          if (resEvent) {
            if (!resEvent.context) {
              resEvent.context = {}
            }

            resEvent.context[key] = value
          }
        }
      }

      if (import.meta.client) {
        const payload = getPayload()

        payload.context[key] = value
      }
    }
  }))

  if (import.meta.server) {
    try {
      if (!event) {
        event = useNuxtApp().ssrContext?.event
      }
    } catch (e) {}

    data.value = event?.context?.[key]
  }

  if (!data.value && defaultValue) {
    data.value = defaultValue
  }

  return data
}
