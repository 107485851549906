import { useNuxtApp } from '#app'
import { watchEffect, ref } from 'vue'
import { useContextData } from './context-data'

import type { UnwrapRef } from 'vue'

interface Options {
  disableHydration?: boolean
}

export function useHydrationStore<T extends object> (
  key: string,
  defaultValue: T,
  options: Options = {}
) {
  const context = useContextData<any>(key, defaultValue)
  const store = ref<T>(context.value)

  if (!store.value) {
    store.value = defaultValue as UnwrapRef<T>
  }

  if (!options.disableHydration) {
    const payload = useNuxtApp().payload

    if (payload.state[key]) {
      store.value = payload.state[key]
    } else {
      payload.state[key] = store.value
    }

    watchEffect(() => {
      if (payload.state[key]) {
        payload.state[key] = store.value
      }
    }, { flush: 'sync' })
  }

  return store
}
